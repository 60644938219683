import React, { useEffect, useState } from 'react';
import { OrderData } from '../CheckoutClasses';
import { InstructionsOxxo, InstructionsSPEI } from '@arema/components';
import { Button, Groupper, Header, Image, Input, Message, Stat } from 'react-frontier';
import { addCommas, formatSeatNumber } from '@arema/components/Util';
import { CDN_URL, PLACEHOLDER_IMG } from '../CheckoutConfig';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import moment from 'moment';

import '../styles/checkout_end.scss';

interface EndProps{
	data: OrderData,
	onForcePayment: ()=>void,
}
var Checkout = (props: EndProps)=>{
	var { t } = useTranslation();
	var { order, tickets } = props.data;
	var tickets_total = 0, commission = 0;
	var total_final = order.delivery_cost+order.payment_cost;
	var total_pending = (order.paid ? 0 : order.delivery_cost+order.payment_cost);
	var tickets_pending = 0, tickets_paid = 0;
	
	for(var i of tickets){
		if(!i.paid){
			tickets_pending++;
			total_pending += i.commission+i.ticket_cost;
			if(order.paid) continue;
		}
		tickets_paid++;
		total_final += i.commission+i.ticket_cost;
		tickets_total += i.ticket_cost;
		commission += i.commission;
	}

	return <div style={{ paddingBottom: 50 }}>
		<Groupper width={600} className='main'>
			<i className={classNames('main', 'check icon', {
				pending: !order.paid
			})}></i>
			<Header text={order.paid ? t('end.paid.title') : t('end.pending.title')} />
			{order.paid ? <>
				<p>
					<Trans i18nKey={'end.paid.text'} values={{ email: order.email }} />
				</p>
				<p style={{ textAlign: 'center', fontWeight: 'bold' }}>
					{t('end.paid.email_sent')}
				</p>
			</> : <p style={{ textAlign: 'center' }}>
				<Trans i18nKey={'end.pending.text'} values={{ email: order.email }} />
				<br />
				{t('end.pending.email_sent')}
			</p>}
			<Input label={t('end.order_hash')} value={order.order_hash} className='hash' labelStyle={{ textAlign: 'center' }} />
			{!!!order.paid && <>
				<Stat label={t('end.payment_deadline')} value={moment.unix(order.date_expiration).format('DD/MMM/YY HH:mm')} className='expiration' />
				<div className="fr divider"></div>
			</>}
			<Stat.Group size='big'>
				<Stat value={tickets_paid.toString()} label={t('common.tickets', { count: tickets_paid })} />
				<Stat value={'$'+addCommas(order.paid ? total_final : total_pending, true, true)} label={order.paid ? (tickets_pending>0 ? t('common.total') : t('end.total_paid')) : t('end.total_pending')} />
			</Stat.Group>
			{!!order.paid && (
				<div className="actions">
					{order.tickets_url ? (
						<a className="fr black button" href={order.tickets_url} target="_blank">
							<i className="pdf file icon"></i>
							{t('end.download_tickets')}
						</a>
					) : (
						<Message type='info'>
							<Trans i18nKey={'end.error_link'} />
						</Message>
					)}
				</div>
			)}
		</Groupper>
		{!!!order.paid && (
			<Groupper className='instructions' title={t('end.instructions.title')} width={600}>
				{order.payment_method==20 ? (
					<InstructionsOxxo amount={order.paid ? total_final : total_pending} download_url={props.data.order.tickets_url} reference={props.data.order.reference} />
				) : order.payment_method==40 ? (
					<InstructionsSPEI amount={order.paid ? total_final : total_pending} download_url={props.data.order.tickets_url} />
				) : null}
			</Groupper>
		)}
		{order.paid && tickets_pending>0 ? (
			<Message style={{ maxWidth: 500, margin: 'auto', marginTop: 10, textAlign: 'center' }}>
				<div className="header">{t('end.pending.message_title')}</div>
				<Trans i18nKey={'end.pending.message_text'} count={tickets_pending} values={{ total: total_pending }} />
				<br />
				<Button text={t('end.pending.message_button')} color='black' style={{ marginTop: 15 }} onClick={props.onForcePayment}/>
			</Message>
		) : null}
		<Groupper className='tickets' width={500} title={t('common.tickets', { count: tickets_paid })} fitted>
			<div className="fr items tickets divided">
				{(order.paid ? tickets.filter(a=>a.paid) : tickets).map(a=>(
					<div className="item" key={`tkt-${a.ticket_hash}`}>
						<Image src={`${CDN_URL}/events/${a.event_id}/800.webp`} alt={a.event_name} fallback={PLACEHOLDER_IMG} />
						<div className="info">
							<div className="event">{a.event_name}</div>
							<div className="date">{moment.unix(a.ticket_date || a.date).format('DD/MMM/YY HH:mm')}</div>
							<div className="section meta">
								{a.section_name} - {a.price_name}
							</div>
							{a.numbered ? (
								<div className="seat meta">
									{a.seat_section || t('common.seat')} {formatSeatNumber(a.seat_row, a.seat_number)}
								</div>
							) : null}
							<div className="cost">${addCommas(a.ticket_cost+a.commission)}</div>
						</div>
					</div>
				))}
			</div>
			<table className="fr table">
				<tbody>
					<tr>
						<td>{t('common.tickets')}</td>
						<td className="collapsing">${addCommas(tickets_total)}</td>
					</tr>
					<tr>
						<td>{t('common.commission')}</td>
						<td className="collapsing">${addCommas(commission)}</td>
					</tr>
					<tr>
						<td>{t('common.delivery')}</td>
						<td className="collapsing">${addCommas(order.delivery_cost)}</td>
					</tr>
					<tr>
						<td>{t('common.payment_method')}</td>
						<td className="collapsing">${addCommas(order.payment_cost)}</td>
					</tr>
					<tr>
						<td><b>{t('common.total')}</b></td>
						<td className="collapsing" style={{ color: 'brown' }}>${addCommas(total_final)}</td>
					</tr>
				</tbody>
			</table>
		</Groupper>
	</div>
}

export default Checkout;