import React, { useEffect, useState } from 'react';
import API from '../CheckoutAPI';
import { Button, Groupper, Message } from 'react-frontier';
import { OrderData } from '../CheckoutClasses';
import { formatSeatNumber, getTicketFormPayload, ticketPayloadFormData } from '@arema/components/Util';
import { TicketQuestion } from '@arema/components/Classes';
import { TicketForm } from '@arema/components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

interface FormProps{
	order: OrderData,
	token: string,
	onFormFinish: (form: OrderData["tickets"])=>void,
}

var CheckoutForm = (props: FormProps)=>{
	var { t } = useTranslation();
	var [form, setForm] = useState<OrderData["tickets"]>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);
	var [sending, setSending] = useState<boolean>(false)

	useEffect(()=>{
		setForm(props.order.tickets);
	}, [props.order]);

	if(!form) return null;

	var onFormChange = (ticket_hash: string)=>{
		return (v: TicketQuestion[])=>{
			var f = [...form];
			var fx = f.findIndex(a=>a.ticket_hash==ticket_hash);
			if(fx==-1) return;
			f[fx].questions = v;
			setForm(f);
		}
	}

	var submit = ()=>{
		var ticket_forms = [], missing = false;
		for(let i of form){
			var payload = getTicketFormPayload(i.questions);
			for(let p of payload){
				if(p.required && p.empty){
					missing = true;
					break;
				}
			}
			ticket_forms.push({
				ticket_id: i.ticket_hash,
				form: payload
			});
		}
		if(missing){
			return setErrorPrompts(['Uno o mas formularios tienen respuestas faltantes.']);
		}
		var formdata = ticketPayloadFormData(ticket_forms);
		setErrorPrompts(null);
		setSending(true);
		API.sendForm(props.token, formdata).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			props.onFormFinish(form);
		}).catch(err=>{
			setErrorPrompts(['Hubo un error iensperado mandando el formulario (LCL-1)']);
		}).finally(()=>{
			setSending(false);
		})
	}

	var completed_forms = 0;
	for(var i of form){
		var valid = true;
		for(var q of i.questions){
			if(q.required && !((q.answer && q.answer.length>0) || q.value_id || (q.selected && q.selected.length>0))){
				valid = false;
				break;
			}
		}
		if(valid) completed_forms++;
	}

	return <div>
		<Message type='info' style={{ padding: '10px 15px', margin: '0 auto 10px auto', maxWidth: 500 }}>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<div style={{ fontWeight: 'bold', fontSize: 20 }}>
					{t('form.forms', { count: completed_forms, total: form.length })}
				</div>
				<Button text={t('common.send')} color='blue' onClick={submit} loading={sending} />
			</div>
		</Message>
		<Message list={errorPrompts} type='error' />
		{form.map((a, i)=>(
			<Groupper title={`${t('form.ticket')} ${a.ticket_hash}`} titleCentered width={500} fitted style={{ marginBottom: 20 }} key={`frm-tk${a.ticket_hash}`}>
				<div className="section" style={{ width: '100%', backgroundColor: '#f0f0f0', display: 'flex', padding: 10, borderTop: '1px solid #E0E0E0' }}>
					<div style={{ flexGrow: 100 }}>
						<div><b>{a.event_name}</b></div>
						<div>{moment.unix(a.ticket_date || a.date).format('DD/MM/YY hh:mm a')}</div>
					</div>
					<div style={{ textAlign: 'right' }}>
						<div>{a.price_name}</div>
						{a.numbered ? (
							<div className="seat">
								{a.seat_section || t('form.seat')}{' '}
								{formatSeatNumber(a.seat_row, a.seat_number)}
							</div>
						) : <div>{t('form.ticket')} {i+1}</div>}
					</div>
				</div>
				<div style={{ padding: 15 }}>
					<TicketForm disabled={sending} form={a.questions} onChange={onFormChange(a.ticket_hash)} />
				</div>
			</Groupper>
		))}

		<div style={{ textAlign: 'center', color: 'gray', fontWeight: 'bold', marginTop: 5 }}>
			{t('common.order')} {props.order.order.order_id ? `@${props.order.order.order_id}` : props.order.order.order_hash}
		</div>
	</div>
}

export default CheckoutForm;