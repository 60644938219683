import React, { useEffect, useState } from 'react';
import { formatSeatNumber } from '../Util';
import { SeatStatus, SeatmapSeat, SectionSeatmap } from '../Classes';
import { Header } from 'react-frontier';
import Seatmap from './Seatmap';
import '@arema/components/style/seatmap.scss';

interface SeatmapProps{
	seatmap: SectionSeatmap,
	loading: boolean,
	error?: string,
	selected: number[],
	onSelected: (seats: SeatmapSeat[])=>void,
}

var SeatmapSelect = (props: SeatmapProps)=>{
	const DEBUG = parseInt(process.env.REACT_APP_DEBUG)===1;
	var { seatmap } = props;
	var map : SeatmapSeat[][] = [];
	if(seatmap){
		var has_stage = (seatmap.stage_width && seatmap.stage_width>0) && (seatmap.stage_height && seatmap.stage_height>0) && (seatmap.stage_x && seatmap.stage_y);
		for(let y=0; y<seatmap.seats_height; y++){
			var row : SeatmapSeat[] = [];
			for(let x=0; x<seatmap.seats_width; x++){
				var is_stage = has_stage && (seatmap.stage_x<=x && (seatmap.stage_x+seatmap.stage_width)>x) && (seatmap.stage_y<=y && (seatmap.stage_y+seatmap.stage_height)>y);
				if(is_stage){
					row.push({
						seat_id: null,
						seat_x: x,
						seat_y: y,
						stage: true,
					});
				}else{
					var seat = props.seatmap.seats.find(a=>a.seat_x===x && a.seat_y===y);
					row.push(seat || null);
				}
			}
			map.push(row);
		}
	}

	var onSeatClick = (seat_id: number)=>{
		return ()=>{
			if(!props.onSelected) return;
			var seat = seatmap.seats.find(a=>a.seat_id==seat_id);
			if(!seat || seat.seat_status!=SeatStatus.FREE) return;
			var selected_seats = props.selected ? seatmap.seats.filter(a=>props.selected.indexOf(a.seat_id)!=-1) : [];
			var ix = selected_seats.findIndex(a=>a.seat_id==seat_id);
			if(ix!=-1){
				selected_seats.splice(ix, 1);
			}else{
				selected_seats.push(seat);
			}
			props.onSelected(selected_seats);
		}
	}

	return <div style={{ flex: 1, minHeight: 600, display: 'flex' }}>
		{props.error ? (
			<Header text='Error' subtext={props.error} containerStyle={{ flexGrow: 100, marginTop: 20 }} iconName='exclamation-circle' />
		) : props.loading ? (
			<Header text='Cargando mapa de butacas...' loading containerStyle={{ flexGrow: 100 }} />
		) : (
			<Seatmap initialScroll={Math.floor(props.seatmap.seats_width/2)*60}>
				{map.map((row, i)=>(
					<Seatmap.Row key={`smprw-${i}`} seatAmount={props.seatmap.seats_width}>
						{row.map((seat, si)=>{
							var key = `smpst-${i}-${si}`
							if(!seat){
								return <Seatmap.Seat empty key={key} />
							}else if(seat.stage){
								if(seat.seat_x!=seatmap.stage_x) return null;
								var stage_row = seat.seat_y-seatmap.stage_y;
								var is_center = Math.max(Math.min(Math.floor(seatmap.stage_height/2), seatmap.stage_height), 0)==stage_row;
								var is_top = seat.seat_y==seatmap.stage_y, is_bottom = seat.seat_y==(seatmap.stage_y+seatmap.stage_height-1);

								return <Seatmap.Seat key={key} stage={[
									is_top ? 1 : 0, is_top ? 1 : 0,
									is_bottom ? 1 : 0, is_bottom ? 1 : 0,
								]} size={seatmap.stage_width} width={64} height={is_top || is_bottom ? 42 : 44} style={{
									marginTop: is_top ? 2 : 0,
									marginLeft: 0,
									marginRight: 0
								}}>
									{is_center ? 'Escenario' : null}
								</Seatmap.Seat>
							}

							return <Seatmap.Seat 
								color={seat.seat_status!==SeatStatus.FREE ? 'red' : (props.selected.indexOf(seat.seat_id)>-1 ? 'orange' : (seat.special ? 'blue' : (seat.ticket_id ? 'green' : 'gray')))}
								subtext={seat.seat_section}
								meta={DEBUG && seat.seat_id.toString()}
								onClick={onSeatClick(seat.seat_id)}
							>
								{seat.seat_status===SeatStatus.LOCKED ? (
									<i className='icon lock'></i>
								) : null}
								{formatSeatNumber(seat.seat_row, seat.seat_number)}
							</Seatmap.Seat>
						})}
					</Seatmap.Row>
				))}
			</Seatmap>
		)}
	</div>
}

export default SeatmapSelect;