import React, { PropsWithChildren } from 'react';
import { bindChange } from '../Util';
import { QuestionType, TicketQuestion } from '../Classes';
import { Checkbox, Dropdown, Rating } from 'semantic-ui-react';
import { firstBy } from 'thenby';
import { Input, Field, Image } from 'react-frontier';
import { useTranslation } from 'react-i18next';
import Dropzone from './Dropzone';

interface TicketFormProps extends PropsWithChildren{
	form: TicketQuestion[],
	disabled?: boolean,
	onChange: (v: TicketQuestion[])=>void,
}
var TicketForm = (props: TicketFormProps)=>{
	var { t, i18n } = useTranslation();
	var { form, onChange } = props;
	if(!form) return null;

	var questionChangeText = (question_id: number)=>{
		return (val: string)=>{
			if(!form) return;
			var fm = [...form];
			var ix = fm.findIndex(a=>a.question_id==question_id);
			if(ix==-1) return;
			fm[ix].answer = val;
			onChange(fm);
		}
	}

	var questionSemantic = (question_id: number, value?: any)=>{
		return (ev: any, data: { type?: string, value?: any, checked?: boolean, rating?: number | string })=>{
			if(!form) return;
			var fm = [...form];
			var ix = fm.findIndex(a=>a.question_id==question_id);
			if(ix==-1) return;
			if(data.type==='checkbox'){
				if(!fm[ix].selected) fm[ix].selected = [];
				if(data.checked){
					fm[ix].selected.push(value);
				}else{
					fm[ix].selected = fm[ix].selected.filter(a=>a!=value);
				}
			}else if(typeof data.rating!=='undefined'){
				fm[ix].answer = data.rating.toString();
			}else{
				if(fm[ix].question_type==QuestionType.YESNO){
					fm[ix].answer = data.value;
					fm[ix].value_id = data.value;
				}else{
					fm[ix].value_id = data.value;
				}
			}
			onChange(fm);
		}
	}

	var handleUpload = (question_id: number)=>{
		return (files: File[])=>{
			var fm = [...form];
			var ix = fm.findIndex(a=>a.question_id==question_id);
			if(ix==-1) return;
			fm[ix].files = files;
			onChange(fm);
		}
	}

	return <div>
		{form.sort(firstBy('sort_order', 'desc').thenBy('question_id', 'asc')).map(a=>{
			var QuestionInput = ()=>{
				switch(a.question_type){
					case QuestionType.OPEN:
						return <Input readonly={props.disabled} label={a.question} comment={a.comment} value={a.answer} required={!!a.required} onChange={questionChangeText(a.question_id)} />
					case QuestionType.TEXTBOX:
						return <Field label={a.question} comment={a.comment} required={!!a.required}>
							<textarea readOnly={props.disabled} value={a.answer} onChange={bindChange(questionChangeText(a.question_id))} />
						</Field>
					case QuestionType.YESNO:
					case QuestionType.SELECTION:
						return <Field label={a.question} comment={a.comment} required={!!a.required}>
							<Dropdown disabled={props.disabled} selection placeholder={a.comment || a.question} onChange={questionSemantic(a.question_id)} value={a.question_type==QuestionType.YESNO ? parseInt(a.answer) : a.value_id} selectOnBlur={false} options={(
								a.question_type==QuestionType.YESNO ? (
									[{ text: t('common.yes'), value: 1 }, { text: t('common.no'), value: 0 }]
								) : (
									a.values.map(a=>({
										value: a.value_id,
										text: a.value,
									}))
								)
							)} />
						</Field>
					case QuestionType.MULTIPLE:
						return <Field label={a.question} comment={a.comment} required={!!a.required}>
							{a.values.map(v=>(
								<Checkbox disabled={props.disabled} key={`frm-asw-${a.question_id}-${v.value_id}`} label={v.value} style={{ display: 'block', marginBottom: 5 }} checked={a.selected ? a.selected.indexOf(v.value_id)!=-1 : false} onChange={questionSemantic(a.question_id, v.value_id)} />
							))}
						</Field>
					case QuestionType.RATING:
						return <Field label={a.question} comment={a.comment} required={!!a.required}>
							<Rating disabled={props.disabled} icon='star' maxRating={10} rating={parseInt(a.answer)} size='large' onRate={questionSemantic(a.question_id)} />
						</Field>
					case QuestionType.FILE_DOWNLOAD:
						return null;
					case QuestionType.FILE_IMAGE:
						return <Field label={a.question} comment={a.comment} required={!!a.required}>
							{a.answer_id && a.answer && (
								<Image hideOnFail src={a.answer} style={{ maxHeight: 200, borderRadius: 8, display: 'block', margin: 'auto', marginBottom: 15, maxWidth: '100%' }} />
							)}
							<Dropzone disabled={props.disabled} valid={['.png', '.jpg', '.jpeg', '.webp', '.gif', '.bmp']} onFiles={handleUpload(a.question_id)} icon='image' text={t('form.dropzone_image')} />
						</Field>
					case QuestionType.FILE_OTHER:
						return null;
					case QuestionType.DATE:
						return <Input readonly={props.disabled} calendar={{ date: a.answer ? parseInt(a.answer) : null, mode: 'date', locale: i18n.language as any }} required={!!a.required} label={a.question} comment={a.comment} value={a.answer ? parseInt(a.answer) : null} onChange={questionChangeText(a.question_id)} />
					case QuestionType.STATIC_TEXT:
						return <Field label={a.question}>
							<textarea readOnly>{a.comment}</textarea>
						</Field>

					default: return null;
				}
			}

			return <div className="ar answer" key={`frm-asw-${a.question_id}-${a.answer_id || 'null'}`}>
				{QuestionInput()}
			</div>
		})}
	</div>
}

export default TicketForm;